import AjaxForm from "servos/ajaxForm"

import Widget from "widgets/Widget"

const onSuccess = function(response) { window.location.href = response.data.redirect_to }
const onError   = function(response) {
  swal({
    title:  "Error",
    text:   response.errors,
    type:   "error",
    html:   true,
  })
  this.buttons.prop("disabled", false)
}
const onComplete  = function(response) {
  // noop
}


class ResumeModal extends Widget {
  bind() {
    this.bindForms(this.root.find("form.resume-enrolment"), { method: "PUT", dontEnable: true })
    this.bindForms(this.root.find("form.view-enrolment"), { method: "PUT", dontEnable: true })
    this.bindForms(this.root.find("form.new-enrolment"), { method: "POST", dontEnable: true, beforeAjax: this.anyCurrentEnrolments.bind(this) })

    this.inProgress = this.root.find(".panel.in-progress")

    this.buttons = this.root.find(".btn")
    this.bindButtons()
  }

  bindForms(forms, options = {}) {
    forms.map((idx, form) => {
      const formServo = new AjaxForm($(form), options)
      formServo.on("success", onSuccess.bind(this))
      formServo.on("error", onError.bind(this))
      formServo.on("complete", onComplete.bind(this))
    })
  }

  bindResumeForms(forms, options = {}) {
    forms.map((idx, form) => {
      const formServo = new AjaxForm($(form), options)
      formServo.on("success", onSuccess.bind(this))
      formServo.on("error", onError.bind(this))
      formServo.on("complete", onComplete.bind(this))
    })
  }

  bindViewForms(forms, options = {}) {
    forms.map((idx, form) => {
      const formServo = new AjaxForm($(form), options)
      formServo.on("success", onSuccess.bind(this))
      formServo.on("error", onError.bind(this))
      formServo.on("complete", onComplete.bind(this))
    })
  }

  bindNewForms(forms, options = {}) {
    forms.map((idx, form) => {
      const formServo = new AjaxForm($(form), options)
      formServo.on("success", onSuccess.bind(this))
      formServo.on("error", onError.bind(this))
      formServo.on("complete", onComplete.bind(this))
    })
  }

  bindButtons() {
    this.root.on("click", ".btn", e => {
      const button = $(e.currentTarget)
      button.prop("disabled", true)

      button.closest("form").submit()
    })
  }

  anyCurrentEnrolments(promiseCallbacks) {
    console.log(this.inProgress.length)

    if (this.inProgress.length === 0) {
      promiseCallbacks.resolve()
    } else {
      return swal({
        title:  "Enrolments in progress",
        text:   "You already have enrolments in progress.<br>Are you sure you want to start a new one?",
        type:   "info",
        html:   true,
        showCancelButton: true
      }, (isConfirm) => {
        if (isConfirm) {
          console.log("Starting new Enrolment")
          promiseCallbacks.resolve()
        } else {
          console.log("Cancelling")
          promiseCallbacks.reject()
          this.buttons.prop("disabled", false)
        }
      })
    }
  }
}

export default ResumeModal
