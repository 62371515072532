import AjaxForm from "servos/ajaxForm"

import Widget from "widgets/Widget"
import ResumeModal from "widgets/ResumeModal"

const onSuccess = function(response) {
  const tree = $(response.data.html)

  $("body").append(tree)
  window.modal.call(".resume-modal")

  const authLinks   = new AuthenticatedLinks($(".auth-links"), response.data.auth_links)
  const resumeModal = new ResumeModal($(".resume-modal"))
}

const onFormError = function(response) { this.replaceHtml(response.jqXHR.responseJSON.html) }

const onError = function(response) {
  if (response.jqXHR.state === 403) {
    console.log("cooldown triggered")
    const wait_time = moment.duration(response.jqXHR.responseJSON.cooloff_time)
    const err_msg   = $('<div class="alert alert-danger" role="alert">Too many failed login attempts. You must wait ' + wait_time.humanize() +
                      ' before trying again. Alternatively you can reset your password by clicking "Forgot Password" below.</div>')
    this.root.children(".alert").remove()
    this.root.prepend(err_msg)
  }
}


class LoginForm extends Widget {
  bind() {
    const formServo = new AjaxForm(this.root)

    formServo.on("success", onSuccess.bind(this))
    formServo.on("error", onError.bind(this))
    formServo.on("422", onFormError.bind(this))
  }
}


class AuthenticatedLinks extends Widget {
  constructor(root, content) {
    super(root)

    this.replaceHtml(content)
  }

  bind() {
    this.bindModalLinks()
  }

  bindModalLinks() {
    $("a[data-toggle='modal']").on("click", e => {
      const elem    = e.currentTarget
      const content = elem.dataset.content

      // We may have normal modal links, so only use the Modal class for ones with data-content
      if (content) {
        window.modal.call(content)
      }
    })
  }
}


class LoginModal extends Widget {
  bind() {
    const resumeLink = this.root.find(".resume-link")
    resumeLink.on("click", e => {
      e.preventDefault()

      const elem = e.currentTarget
      window.modal.call(elem.dataset.content)

      // We must create the login widget after the content is loaded or we won't bind
      // the AJAXForm to the correct DOM element
      const widget = new LoginForm($(`${elem.dataset.content} form`))
    })
  }
}

export default LoginModal
