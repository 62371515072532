eventfulMixin = require("mixins/eventful")

class AjaxForm
  constructor: (form, config = {}) ->
    eventfulMixin.call(this)

    @buttons = form.find("input[type='submit'], button[type='submit']")

    cacheButtonContent(@buttons)

    @config = config
    @form   = form

    @bindForm(form)

  bindForm: (form) ->
    form.submit (e) =>
      e.preventDefault()

      unless @config.dontDisable
        disableButtons(@buttons)

      # Perform any work before the AJAX request
      promise = new Promise((resolve, reject) =>
        if @config.beforeAjax
          # Perform other work and wait for promise to be resolved
          @config.beforeAjax
            resolve: resolve
            reject: reject
        else
          # Just resolve so we can continue ajaxing
          resolve()
        return
      )

      # Once promise is resolved, do the ajax call
      promise.then(doAjax.bind(this))

      # In case of error, do nothing but enable buttons
      promise.catch(() =>
        enableButtons(@buttons)
      )


cacheButtonContent = (buttons) ->
  _.each buttons, (button) ->
    button = $(button)
    if button.prop("tagName") is "INPUT"
      button.prop("originalValue", button.val())
    else
      button.prop("originalHtml", button.html())


disableButtons = (buttons) ->
  _.each buttons, (button) ->
    button = $(button)
    disableWith = button.data("disable-with")

    if disableWith
      if button.prop("tagName") is "INPUT"
        button.val(disableWith)
      else
        button.html(disableWith)
  buttons.prop("disabled", true)


enableButtons = (buttons) ->
  _.each buttons, (button) ->
    button = $(button)
    if button.prop("tagName") is "INPUT"
      button.val(button.prop("originalValue"))
    else
      button.html(button.prop("originalHtml"))
  buttons.prop("disabled", false)


doAjax = ->
  $.ajax
    method: @config["method"] || @form.prop("method" || "POST")
    url: @config["action"] || @form.prop("action")
    data: @form.serialize()
    success: success.bind(this)
    error: error.bind(this)
    complete: complete.bind(this)


success = (data, status, jqXHR) ->
  @emit("success", {
    data: data,
    status: status,
    jqXHR: jqXHR
  })


error = (jqXHR, status, errorThrown) ->
  if jqXHR.status is 422
    errorType = 422
  else
    errorType = "error"

  @emit(errorType, {
    jqXHR: jqXHR,
    status: status,
    errorThrown: errorThrown
  })


complete = (jqXHR, status) ->
  @emit("complete", {
    jqXHR: jqXHR,
    status: status
  })

  unless @config.dontEnable
    enableButtons(@buttons)

module.exports = AjaxForm
