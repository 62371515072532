import Modal from "servos/modal"

import LoginModal  from "widgets/LoginModal"
import ResumeModal from "widgets/ResumeModal"

/**
 * initializeGenericModal - initialize the modal links, which select content and insert into the modal
 *
 * do not use this for any modals where you need to do some kind of binding, you will want to call
 * them explicitly.
 */
const initializeGenericModal = () => {
  window.modal = new Modal()

  const links = document.querySelectorAll("a[data-toggle='modal']")
  Array.prototype.map.call(links, link => {
    link.addEventListener("click", e => {
      const contentSelector = e.currentTarget.dataset.content

      // We may have a normal modal link, we only want to process if this
      // has the special data-content attr
      // not sure why we don't use that as the selector
      if (contentSelector) {
        window.modal.call(contentSelector)
      }
    })
  })
}

/**
 * initializeLoginRegisterModal - initialise the Login/Register modals
 *
 * these are special modals, which need custom binding and we don't want to generically
 * apply themas above
 */
const initializeLoginRegisterModal = () => {
  const loginRoot = document.getElementsByClassName("login-modal")
  const resumeRoot = document.getElementsByClassName("resume-modal")

  if (loginRoot.length > 0) {
    // eslint-disable-next-line no-unused-vars
    const widget = new LoginModal(document.body)
  }

  if (resumeRoot.length > 0) {
    // eslint-disable-next-line no-unused-vars
    const widget = new ResumeModal(resumeRoot)
  }
}

document.addEventListener("DOMContentLoaded", () => {
  initializeGenericModal()

  initializeLoginRegisterModal()
})
