import Eventful from "mixins/eventful"

class Widget {
  constructor(root, opts = {}) {
    Eventful.call(this)

    this.root = $(root)

    this.opts = {}
    Object.assign(this.opts, opts)

    if (this.bind) {
      this.bind()
    }
  }

  // replaceHtml
  //
  // replaces the DOM elements and pointer with new HTML
  // if a bind method exists rebind
  replaceHtml(html, opts = {}) {
    let tree = $(html)

    this.root.replaceWith(tree)
    this.root = tree

    if (this.bind) {
      this.bind()
    }
    this.emit('htmlReplaced')
  }
}

export default Widget
