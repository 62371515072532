debug = (thing, message) ->
  obj =
    caller: thing
    event: message.event
    eventName: message.eventName
    target: message.target
    data: message.data
    func: message.func
  console.log obj if window.debugEvents

eventfulMixin = ->
  self = this

  self.on = (event, func) ->
    $(self).on "ev_#{event}", (event, data) ->
      message =
        event: "on"
        eventName: event.type
        target: event.target
        func: func
        data: data
      debug self, message

      func(data, event)
      return
    return

  self.trigger = (event, data) ->
    message =
      event: "trigger"
      eventName: "ev_#{event}"
      data: data
    debug self, message
    $(self).trigger "ev_#{event}", data
    return

  self.emit = (event, data) ->
    message =
      event: "emit"
      eventName: "ev_#{event}"
      data: data
    debug self, message
    $(self).trigger "ev_#{event}", data
    return

  return

module.exports = eventfulMixin
