class Modal
  # Modal Class - will load content for the modal given a selector
  constructor: ->
    @modal                = $("#modal")
    @modalDialog          = @modal.find(".modal-dialog")
    @modalContent         = @modal.find(".modal-content")
    @modalHeader          = @modal.find(".modal-header")
    @modalHeaderOriginal  = @modalHeader.children()
    @modalBody            = @modal.find(".modal-body");
    @modalFooter          = @modal.find(".modal-footer")
    @map                  = {}

  loadContent: (selector) ->
    @modal.attr("content", selector)

    if selector of @map
      @modalDialog.empty()
      @modalDialog.append(@map[selector])
    else
      content = $(selector)
      if content.length == 0
        throw "Element matching #{selector} does not exist"

      @modalContent.removeClass().addClass("modal-content").addClass(selector.replace(".", ""))

      contentHeader = content.find(".content-header")
      if contentHeader.length != 0
        @modalHeader.empty()
        @modalHeader.append(contentHeader)
      else
        @modalHeader.empty()
        @modalHeader.replaceWith(@modalHeaderOriginal)

      contentBody = content.find(".content-body");
      @modalBody.empty()
      @modalBody.append(contentBody)

      contentFooter = content.find(".content-footer");
      @modalFooter.empty()
      @modalFooter.append(contentFooter)

      currentModal = @modalContent.clone()
      @map[selector] = currentModal

      content.remove()

      @modal.modal("handleUpdate")

  call: (selector) ->
    @loadContent(selector)
    @open()

  open: ->
    @modal.modal("show")

  close: ->
    @modal.modal("hide")

  clear: ->
    @map = {}
    @modalContent.removeClass()
    @modalBody.empty()

module.exports = Modal
